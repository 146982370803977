<template>
    <div class="box">
        <div class="title-box">
            <h3>欢迎使用</h3>
            <p>实名制登记预约平台</p>
        </div>
        <div class="process-box">
            <h4>登记流程</h4>
            <ul>
                <!--<li>-->
                    <!--<i><img src="../../assets/images/cheguanli.png"></i>-->
                    <!--<div>-->
                        <!--<h5>第一步选择出行方式</h5>-->
                        <!--<p>选择自驾、大巴、网约车、火车、飞机、其他</p>-->
                    <!--</div>-->
                <!--</li>-->
                <!--<li>-->
                    <!--<i><img src="../../assets/images/xinxi.png"></i>-->
                    <!--<div>-->
                        <!--<h5>第二步填写相关信息</h5>-->
                        <!--<p>填写交通信息，个人真实信息等</p>-->
                    <!--</div>-->
                <!--</li>-->
                <!--<li>-->
                    <!--<i><img src="../../assets/images/yuyue.png"></i>-->
                    <!--<div>-->
                        <!--<h5>第三步选择预约票</h5>-->
                        <!--<p>选择预约票，提交登记成功</p>-->
                    <!--</div>-->
                <!--</li>-->
                <!--<li>-->
                    <!--<i><img src="../../assets/images/qrcode.png"></i>-->
                    <!--<div>-->
                        <!--<h5>第四步出示登记信息</h5>-->
                        <!--<p>检验人员进行查验，即可入园</p>-->
                    <!--</div>-->
                <!--</li>-->
                <!--隐藏出行方式选择-->
                <li>
                    <i><img src="../../assets/images/xinxi.png"></i>
                    <div>
                        <h5>第一步填写相关信息</h5>
                        <p>填写个人真实信息等</p>
                        <!--<p>填写交通信息，个人真实信息等</p>-->
                    </div>
                </li>
                <li>
                    <i><img src="../../assets/images/yuyue.png"></i>
                    <div>
                        <h5>第二步选择预约票</h5>
                        <p>选择预约票，提交登记成功</p>
                    </div>
                </li>
                <li>
                    <i><img src="../../assets/images/qrcode.png"></i>
                    <div>
                        <h5>第三步出示登记信息</h5>
                        <p>检验人员进行查验，即可入园</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="btn-box">
            <van-button type="primary" @click="doRegister"  round block>开始登记
            </van-button>
            <van-button type="default"  @click="doSuccess"  round block>出示信息
            </van-button>
        </div>
    </div>
</template>

<script>
    import { Button,} from 'vant';
    export default {
        components: {
            [Button.name]: Button,
        },
        name: "index",
        data(){
            return {
                userId:'',
            }
        },
        created(){
            this.userId=this.$store.state.userId;
            // if (code) {
            //     if (!openId) {
            //         this.$post('/tourist/getOpenId', {code}).then(res => {
            //             this.$store.dispatch('saveToken', res.object.token);
            //             this.$store.dispatch('saveOpenId', res.object.openId);
            //             this.userId=res.object.id
            //         })
            //     }else {
            //         this.$post('/tourist/getUserId', {openId}).then(res => {
            //             this.userId = res.object;
            //         })
            //     }
            // }else {
            //     this.$post('/tourist/getUserId', {openId}).then(res => {
            //         this.userId = res.object;
            //     })
            // }
        },
        computed: {
            scenicId(){
                return this.$store.state.scenicId;
            },
            openId(){
                return this.$store.state.openId;
            },
            token(){
                return this.$store.state.token;
            }
        },
        methods:{
            doRegister(){
                // this.$router.replace({path: '/process/traffic/'});
                this.$router.push({path: '/tourist',query:{traffic:0,carInfo:''}});
            },
            doSuccess() {
                if (this.userId == null || this.userId == "" || this.userId == undefined) {
                    this.$router.replace({path: '/tourist/failed'});
                } else {
                    let data = {}
                    data["id"] = this.userId;
                    data["scenicId"] =  this.$store.state.scenicId;
                    this.$post('/tourist/getUserAndScenic', data).then(res=>{
                        if (res.flag !=0){
                            this.$router.replace({path: '/tourist/failed'});
                        } else {
                            this.$router.replace({path: '/tourist/success/' + this.userId + "/" + this.$store.state.scenicId});
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .box {
        min-height: 100vh;
        padding: 0 6vw;
        background: url("../../assets/images/bg.png")  #f7f8fa;
        background-size: 100% auto;
        background-repeat: no-repeat;
    }
    .title-box{
        color: #fff;
        padding: 6vw 0 4vw;
        h3{
            margin: 0;
            font-size:7.2vw;
            font-weight: normal;
        }
        p{
            margin-top: 0;
            font-size: 4vw;
        }
    }
    .process-box {
        padding: 6vw;
        color: #333;
        border-radius: 4vw;
        overflow: hidden;
        background-color: #fff;
        h4{
            margin: 0;
            font-size: 6vw;
            font-weight: normal;
        }
        ul{
            padding: 4vw 0;
            li{
                display: flex;
                align-items: center;
                margin: 6vw 0 8vw;
                color: #333;
                img{
                    width: 8vw;
                    margin-right: 3.6vw;
                }
                h5{
                    margin: 0;
                    font-size: 4.6vw;
                }
                p{
                    margin: 1vw 0 0;
                    font-size:3vw;
                    color: #999;
                }
            }
        }
    }
    .btn-box {
        margin-top: 6vw;
        button{
            margin-bottom: 6vw;
        }
    }
</style>
